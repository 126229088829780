import * as ActionTypes from './AuthActionsTypes';
import { IAuthActions } from './IAuthActions';
import { TUser } from '../../types/IAuthStore';

/** @description Экшен для регистрации юзера */
export function signUp(
  firstName: string, lastName: string, email: string, password: string,
): IAuthActions {
  return {
    type: ActionTypes.SIGN_UP_REQUEST,
    payload: {
      firstName,
      lastName,
      email,
      password,
    },
  };
}

/** @description Экшен для обаботки регистрации */
export function signUpSuccess(): IAuthActions {
  return {
    type: ActionTypes.SIGN_UP_SUCCESS,
    message: 'Код подтверждения отправлен на ваш email',
  };
}

/** @description Экшен для обаботки регистрации */
export function signUpError(error?: string): IAuthActions {
  return {
    type: ActionTypes.SIGN_UP_ERROR,
    error: error || 'Не удалось зарегистрироваться',
  };
}

/** @description Экшен для отправки кода для подтверждения email`a */
export function confirmEmail(code: string): IAuthActions {
  return {
    type: ActionTypes.CONFIRM_EMAIL_REQUEST,
    payload: {
      code,
    },
  };
}

/** @description Экшен для обработки успешного подтверждения email`a */
export function confirmEmailSuccess(): IAuthActions {
  return {
    type: ActionTypes.CONFIRM_EMAIL_SUCCESS,
  };
}

/** @description Экшен для обработки не успешного подтверждения email`a */
export function confirmEmailError(): IAuthActions {
  return {
    type: ActionTypes.CONFIRM_EMAIL_ERROR,
  };
}

/** @description Экшен для авторизации */
export function signIn(email: string, password: string, remember: boolean): IAuthActions {
  return {
    type: ActionTypes.SIGN_IN_REQUEST,
    payload: {
      email,
      password,
      remember,
    },
  };
}

/** @description Экшен для установки активного юзера */
export function signInSuccess(user: TUser): IAuthActions {
  return {
    type: ActionTypes.SIGN_IN_SUCCESS,
    payload: { user },
  };
}

/** @description Экшен для обработки авторизации */
export function getSessionSuccess(): IAuthActions {
  return {
    type: ActionTypes.GET_SESSION_SUCCESS,
  };
}

/** @description Экшен для обработки авторизации и установки активного юзера */
export function signInError(error: string): IAuthActions {
  return {
    type: ActionTypes.SIGN_IN_ERROR,
    error,
  };
}

/** @description Экшен для того что бы разлогиниться */
export function signOut(): IAuthActions {
  return {
    type: ActionTypes.SIGN_OUT,
  };
}

/** @description Экшен для получения сессии */
export function getSession(): IAuthActions {
  return {
    type: ActionTypes.GET_SESSION_REQUEST,
  };
}
