/**
 * Возвращает разницу в процентах
 * @param {number} previous
 * @param {number} current
 * @returns {number}
 */
const getPercentDiff = (previous: number, current: number): number => {
  const previousResult = previous || 1;
  const currentResult = current || 1;

  return Math.round(100 * (currentResult - previousResult) / previousResult);
};


/**
 * Групировка массива по переданным параметрам
 * @param array - группируемый массив
 * @param props - groupByProperties = ['firstParam', 'secondSecond, ...']
 */
function groupBy<T>({ Group: array, By: props }: { Group: Array<T>; By: Array<string>; }): Array<Array<T>> {
  const getGroupedItems = (item: any) => {
    const returnArray = [];
    let i;
    for (i = 0; i < props.length; i += 1) {
      returnArray.push(item[props[i]]);
    }
    return returnArray;
  };

  const groups: any = {};

  let i;

  for (i = 0; i < array.length; i += 1) {
    const arrayRecord = array[i];
    const group: string = JSON.stringify(getGroupedItems(arrayRecord));
    groups[group] = groups[group] || [];
    groups[group].push(arrayRecord);
  }

  return Object.keys(groups).map(group => groups[group]);
}

/** @description Функция для валютного символа */
function getMoneySymbol(countryCode: string): '₽' | '₴' {
  switch (countryCode) {
    case 'RU':
      return '₽';
    case 'UA':
      return '₴';
    default: return '₽';
  }
}

export {
  getPercentDiff,
  groupBy,
  getMoneySymbol,
};
