import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import CabinetPage from './CabinetPage';
import { initStarting } from '../../actions/cabinet/CabinetActions';
import { IAppStore } from '../../types/IAppStore';
import { IStateProps, IDispatchProps } from './ICabinetPage';
import { IAppActions } from '../../types/IAppActions';

import {
  removeOpenReserve,
  confirmOpenReserve,
  rejectOpenReserve,
} from '../../actions/reservationsPage/ReservationsPageActions';

import { removeRestaurantCall } from '../../actions/restaurantsCalls/RestaurantsCallsActions';

import {
  removeNotification,
  toggleNotification,
  closeNotifications,
  idle,
} from '../../actions/notifications/NotificationsActions';

import { notificationsSelector } from '../../containers/Reservation/selectors';

import {
  addAppReserve,
  removeAppReserve,
  toggleAppReserves,
} from '../../actions/appReserves/AppReservesActions';

function mapStateToProps(state: IAppStore): IStateProps {
  const reservationsIsOpen = state.reserveModal.editReserveModalIsOpen
    || state.reserveModal.createReserveModalIsOpen
    || state.reserveModal.confirmReserveModalIsOpen;

  return {
    isInit: state.cabinet.isInit,
    loading: state.loading.isLoading,
    modalOpen: state.cabinet.modalOpen,
    cashBoxStatus: state.cabinet.cashBoxStatus,
    needAuthFromIiko: state.cabinet.iikoAuth.needAuthFromIiko,
    modalAuthIikoOpen: state.cabinet.modalAuthIikoOpen,
    reservationsIsOpen,
    withoutRestaurants: state.cabinet.restaurantList.length === 0,
    pathName: state.router.location.pathname,

    notifications: notificationsSelector(state),
    notificationsOpen: state.notifications.open,

    appReserves: state.appReserves,
    calls: state.calls && state.calls.items,
  };
}

function mapDispatchToProps(dispatch: Dispatch<IAppActions>): IDispatchProps {
  return bindActionCreators({
    initStarting,

    closeNotifications,
    removeNotification,
    toggleNotification,
    idle,

    removeRestaurantCall,
    removeOpenReserve,
    confirmOpenReserve,
    rejectOpenReserve,

    addAppReserve,
    removeAppReserve,
    toggleAppReserves,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CabinetPage);
