import React from 'react';

import {
  withStyles, createStyles, WithStyles, Theme,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import locales from '../../../locales';
import { TRestaurant } from '../../../types/ICabinetStore';

const styles = (theme: Theme) => createStyles({
  formControl: {
    margin: '8px 0',
    [theme.breakpoints.up('md')]: {
      maxWidth: 440,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
    },
    [theme.breakpoints.down(600)]: {
      display: 'none',
    },
  },
});

const CustomField = withStyles({
  root: {
    '& .Mui-disabled': {
      color: '#f7f8fb',
    },
    '& label.Mui-disabled': {
      color: '#8d8d8d',
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottom: 'none',
      // borderBottom: '1px solid',
      // borderBottomColor: '#8d8d8d',
    },
  },
})(TextField);

interface IProps extends WithStyles<typeof styles>{
  setCurrentRestaurant: (id: number) => void;
  currentRestaurant?: TRestaurant;
  restaurantList: Array<TRestaurant>;
}

// TODO Нужно отрефакторить стили
const RestaurantSelect: React.FC<IProps> = ({
  classes,
  // restaurantList,
  currentRestaurant,
  // setCurrentRestaurant,
}) => {
  // const [selected, changeSelected] = useState<number>(currentRestaurant ? currentRestaurant.id : 0);
  const selectedRestaurant = currentRestaurant
    ? `${currentRestaurant.name}, ${currentRestaurant.address.street}, ${currentRestaurant.address.home}`
    : '';

  // useEffect(() => {
  //   changeSelected(currentRestaurant ? currentRestaurant.id : 0);
  // }, [currentRestaurant]);
  //
  // const onChange = (e: React.ChangeEvent<{ name?: string; value: any }>) => {
  //   changeSelected(parseInt(e.target.value));
  //   setCurrentRestaurant(parseInt(e.target.value));
  // };

  return (
    <FormControl fullWidth className={classes.formControl}>
      <CustomField
        label={locales.t('places.restaurantSelect')}
        value={selectedRestaurant}
        disabled
      />
    </FormControl>
  );

  // return (
  //   <FormControl fullWidth className={classes.formControl}>
  //     <InputLabel style={{ color: '#FFF', opacity: 0.5 }} htmlFor="restaurant-label-placeholder">
  //       { locales.t('places.restaurantSelect') }
  //     </InputLabel>
  //     <Select
  //       fullWidth
  //       value={selected}
  //       onChange={onChange}
  //       input={<Input name="currentRestaurant" id="restaurant-label-placeholder" />}
  //       displayEmpty
  //       name="currentRestaurant"
  //     >
  //       { (restaurantList.length > 0)
  //         ? restaurantList.map(item => (
  //           <MenuItem key={item.id} value={item.id}>
  //             { `${item.name}, ${item.address.street}, ${item.address.home}` }
  //           </MenuItem>
  //         ))
  //         : (
  //           <MenuItem value="">
  //             <em>...</em>
  //           </MenuItem>
  //         )
  //       }
  //     </Select>
  //   </FormControl>
  // );
};

export default withStyles(styles)(RestaurantSelect);
