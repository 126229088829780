import React, { PureComponent, Fragment } from 'react';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography';

import { IProps } from './IHomeCont';
import StatisticDateCont from '../StatisticDateCont';
import InitMessages from '../../components/Home/InitMessages';
import ConnectSystemMessage from '../../components/Home/FirstStepConnectSystemMessage';
import ConnectSystemModal from '../../components/Home/ConnectSystemModal';
// import ServiceStatus from '../../components/Home/ServiceStatus'; - Убрали в рамках задачи DEV-244
import locales from '../../locales';
import HomeStatistics from '../../components/Home/HomeStatistics';
import PageHeaderWrap from '../../components/Common/PageHeaderWrap';
import CashBoxWarningCont from '../CashBoxWarning';
import PageContentWrap from '../../components/Common/PageContentWrap';
import { IDate } from '../../types/IDate';

class HomeCont extends PureComponent<IProps, {}> {
  componentDidMount() {
    const { currentRestaurant } = this.props;
    if (currentRestaurant && _.has(currentRestaurant, 'id')) {
      // this.handleGetCashBoxStatus();
      this.handleGetStatistics();
    }
  }

  /** @description Запрашиваем статус ресторана в случае редактирования поля currentRestaurant */
  componentDidUpdate(prevProps: IProps) {
    const { currentRestaurant } = this.props;

    if (!_.isUndefined(currentRestaurant) && prevProps.currentRestaurant !== currentRestaurant) {
      // this.handleGetCashBoxStatus();
      this.handleGetStatistics();
    }
  }

  /** @description Ручка для экшена для получения пинкода ресторана */
  handleGetPinCode = () => {
    const { currentRestaurant, getRestaurantPinCode } = this.props;
    if (currentRestaurant) {
      getRestaurantPinCode(currentRestaurant.id);
    }
  };

  // Убрали в рамках задачи DEV-244
  // /** @description Ручка для октрытия/закрытия окна авторизации в iiko */
  // handleOpenAuthIikoModal = () => {
  //   const { switchAuthIikoModal } = this.props;
  //   switchAuthIikoModal();
  // };
  //
  // /** @description Ручка для переключения приёма брони */
  // handleSwitchBookingProcessor = () => {
  //   const { currentRestaurant, switchBookingProcessor } = this.props;
  //   if (currentRestaurant) {
  //     const processorType = currentRestaurant.settings.bookingProcessor === 'CASHBOX' ? 'API' : 'CASHBOX';
  //
  //     switchBookingProcessor({
  //       restaurantId: currentRestaurant.id,
  //       processorType,
  //     });
  //   }
  // };

  /** @description Ручка для получения статуса ресторана */
  // handleGetCashBoxStatus = () => {
  //   const { currentRestaurant, getCashBoxConnectStatus } = this.props;
  //   if (currentRestaurant && _.has(currentRestaurant, 'id')) {
  //     getCashBoxConnectStatus(currentRestaurant.id);
  //   }
  // };

  /** @description Ручка экшена для получения статистики */
  handleGetStatistics = (date?: IDate) => {
    const { currentRestaurant, getRestaurantStatistics, getRestaurantAttendances } = this.props;

    if (currentRestaurant) {
      getRestaurantStatistics(currentRestaurant.id, date);
      getRestaurantAttendances(currentRestaurant.id, date);
    }
  };

  render() {
    const {
      restaurantList,
      currentRestaurant,
      home,
      // needAuthFromIiko, - Убрали в рамках задачи DEV-244
      cashBoxStatus,
      currentRestaurantStatistic,
      currentRestaurantAttendance,
      openCreateModal,
      switchPinCodeModal,
      loading,
      moneySymbol,
    } = this.props;

    const showFirstStep = restaurantList.length === 0;
    const showSecondStep = !_.isNil(currentRestaurant) && cashBoxStatus === undefined;

    // Убрали в рамках задачи DEV-244
    // const bookingProcessor = !_.isNil(currentRestaurant) && currentRestaurant.settings.bookingProcessor === 'CASHBOX';

    // const ServiceStatusComp = !_.isNil(currentRestaurant) && (
    //   <Grid item md={12} lg={6} xs={12}>
    //     <ServiceStatus
    //       needAuthFromIiko={needAuthFromIiko}
    //       handleOpenAuthIikoModal={this.handleOpenAuthIikoModal}
    //       handleSwitchBookingProcessor={this.handleSwitchBookingProcessor}
    //       bookingProcessor={bookingProcessor}
    //       cashBoxStatus={cashBoxStatus}
    //       handleOpenConnectModal={switchPinCodeModal}
    //     />
    //   </Grid>
    // );

    const isActive = !showFirstStep && !showSecondStep;

    const stepsComponents = () => {
      if (showFirstStep && !loading) {
        return (
          <Grid item lg={6}>
            <InitMessages handleClickOpen={openCreateModal} />
          </Grid>
        );
      } if (showSecondStep && !loading) {
        return (
          <Fragment>
            <Grid item lg={6}>
              <ConnectSystemMessage handleOpenConnectModal={switchPinCodeModal} />
            </Grid>
            {/* { ServiceStatusComp } */}
          </Fragment>
        );
      }
      return (
        <Fragment>
          <HomeStatistics
            statistic={currentRestaurantStatistic}
            attendance={currentRestaurantAttendance}
            moneySymbol={moneySymbol}
          />
          {/* { ServiceStatusComp } */}
        </Fragment>
      );
    };

    return (
      <Fragment>
        <PageHeaderWrap>
          <Typography variant="h6">
            { locales.t('home.title') }
          </Typography>
          { isActive && <StatisticDateCont handleGetStatistics={this.handleGetStatistics} /> }
        </PageHeaderWrap>
        <CashBoxWarningCont />

        { /* direction={isActive ? 'row' : 'column'} - нужно будет вернуть вместе с ServiceStatusComp */ }
        <PageContentWrap fullWidth>
          { stepsComponents() }
        </PageContentWrap>

        <ConnectSystemModal
          pinCode={home.pinCode}
          timeLeft={home.timeLeft}
          connectStatus={home.connectStatus}
          modalOpen={home.pinCodeModal}
          handleGetPinCode={this.handleGetPinCode}
          handleCloseConnectModal={switchPinCodeModal}
        />
      </Fragment>
    );
  }
}

export default HomeCont;
