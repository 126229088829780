import * as _ from 'lodash';

import * as ActionTypes from './CabinetActionsTypes';
import { IAppActions } from '../../types/IAppActions';
import { IRestaurantAddress, TRestaurant, TRestaurantPlugin } from '../../types/ICabinetStore';
import { IDate } from '../../types/IDate';

/** @description Инит проложения */
export function initStarting(): IAppActions {
  return {
    type: ActionTypes.INIT_START,
  };
}

/** @description Окончание инита */
export function initFinished(): IAppActions {
  return {
    type: ActionTypes.INIT_END,
  };
}

/** @description Экшен на получение списка ресторанов */
export function getRestaurantList(): IAppActions {
  return {
    type: ActionTypes.GET_RESTAURANT_LIST_REQUEST,
  };
}

/** @description Экшен для обработки получения списка ресторанов */
export function getRestaurantListSuccess(restaurantList: Array<TRestaurant>): IAppActions {
  return {
    type: ActionTypes.GET_RESTAURANT_LIST_SUCCESS,
    payload: restaurantList,
  };
}

/** @description Экшен для обработки ошибки при получение списка ресторанов */
export function getRestaurantError(error: string): IAppActions {
  return {
    type: ActionTypes.GET_RESTAURANT_LIST_ERROR,
    error,
  };
}

export function addRestaurant(payload: {
  address: IRestaurantAddress;
  name: string;
}): IAppActions {
  return {
    type: ActionTypes.CREATE_RESTAURANT_REQUEST,
    payload,
  };
}

/** @description Экшен успешного создания нового ресторана */
export function addRestaurantSuccess(): IAppActions {
  return {
    type: ActionTypes.CREATE_RESTAURANT_SUCCESS,
    message: 'Ресторан добавлен в систему',
  };
}

/** @description Экшен для обработки ошибки при создании нового ресторана */
export function addRestaurantError(): IAppActions {
  return {
    type: ActionTypes.CREATE_RESTAURANT_ERROR,
    error: 'Не удалось добавить ресторан в систему',
  };
}

/** @description Экшен для обработки создания нового ресторана */
export function updateRestaurantList(restaurant: TRestaurant): IAppActions {
  return {
    type: ActionTypes.UPDATE_RESTAURANT_LIST,
    payload: restaurant,
  };
}

/** @description Экшен Выбор актвиного ресторана */
export function setCurrentRestaurant(restaurantId: number): IAppActions {
  return {
    type: ActionTypes.SET_CURRENT_RESTAURANT,
    payload: {
      restaurantId,
    },
  };
}

/** @description Экшен на открытие окна регистрации ресторана */
export function openCreateModal(): IAppActions {
  return {
    type: ActionTypes.OPEN_MODAL,
  };
}

/** @description Экшен на закрывает окна регистрации ресторана */
export function closeCreateModal(): IAppActions {
  return {
    type: ActionTypes.CLOSE_MODAL,
  };
}

/** @description Экшен на переключение видимости модального окна авторизации в iiko ./components/Common/AuthIikoModal */
export function switchAuthIikoModal(): IAppActions {
  return {
    type: ActionTypes.SWITCH_IIKO_MODAL,
  };
}

/** @description Экшен для проверки статуса "Подключение к системе автоматизации" */
export function getCashBoxConnectStatus(restaurantId: number): IAppActions {
  return {
    type: ActionTypes.CASHBOX_CONNECT_STATUS_REQUEST,
    payload: { restaurantId },
  };
}

/** @description Экшен для обработки успешного запроса */
export function getCashBoxConnectStatusSuccess(active?: boolean): IAppActions {
  if (!_.isUndefined(active) && !_.isNull(active)) {
    return {
      type: ActionTypes.CASHBOX_CONNECT_STATUS_SUCCESS,
      payload: {
        active,
      },
    };
  }

  return {
    type: ActionTypes.CASHBOX_CONNECT_STATUS_SUCCESS,
    payload: {},
  };
}

/** @description Экшен для обработки ошибки запроса */
export function getCashBoxConnectStatusError(): IAppActions {
  return {
    type: ActionTypes.CASHBOX_CONNECT_STATUS_ERROR,
  };
}

/** @description Экшен для проверки статуса плагинов кассы */
export function getCashBoxPluginsStatus(restaurantId: number): IAppActions {
  return {
    type: ActionTypes.CASHBOX_PLUGIN_STATUS_REQUEST,
    payload: { restaurantId },
  };
}

/** @description Экшен для обработки успешного запроса */
export function getCashBoxPluginsStatusSuccess(payload: TRestaurantPlugin[]): IAppActions {
  return {
    type: ActionTypes.CASHBOX_PLUGIN_STATUS_SUCCESS,
    payload,
  };
}

/** @description Экшен для обработки ошибки запроса */
export function getCashBoxPluginsStatusError(): IAppActions {
  return {
    type: ActionTypes.CASHBOX_PLUGIN_STATUS_ERROR,
  };
}

/** @description Экшен не показывает панель warning для ресторана */
export function showWarning(): IAppActions {
  return {
    type: ActionTypes.SHOW_WARNING,
  };
}

/** @description Экшен на закрывает панель warning для ресторана */
export function hideWarning(): IAppActions {
  return {
    type: ActionTypes.HIDE_WARNING,
  };
}

/** @description Экшен для проверки статуса ресторана в Iiko */
export function checkRestaurantFromIiko(restaurantId: number): IAppActions {
  return {
    type: ActionTypes.CHECK_RESTAURANT_FROM_IIKO_REQUEST,
    payload: {
      restaurantId,
    },
  };
}

/** @description Экшен для установки флага "Нужна авторизация в иико" */
export function needAuthFromIiko(): IAppActions {
  return {
    type: ActionTypes.NEED_AUTH_FROM_IIKO,
  };
}

/** @description Экшен для изменения состояния диалогового окна для выбора дат */
export function switchCustomDateModal(): IAppActions {
  return {
    type: ActionTypes.SWITCH_CUSTOM_DATE_MODAL,
  };
}

/** @description Экшен для изменения дат в своем диапозоне */
export function changeCustomDate(date: IDate): IAppActions {
  return {
    type: ActionTypes.CHANGE_CUSTOM_DATE,
    payload: date,
  };
}

/** @description Экшен для переключения аудио нотификаций для приёма резервов */
export function switchAudioNotification() {
  return {
    type: ActionTypes.SWITCH_AUDIO_NOTIFICATION,
  };
}
