import React, { Component } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  withStyles,
  createStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles';

import {
  Badge,
  Tooltip,
  Menu,
  MenuItem,
  Avatar,
  IconButton,
} from '@material-ui/core';

import { EditOutlined as EditIcon } from '@material-ui/icons';
// import { SquareEditOutline as EditIcon } from 'mdi-material-ui';

import locales from '../../../locales';
import { signOut } from '../../../actions/auth/AuthActions';
import {
  openCreateModal, setCurrentRestaurant,
} from '../../../actions/cabinet/CabinetActions';
import { TRestaurant } from '../../../types/ICabinetStore';
import { TUser } from '../../../types/IAuthStore';
import { IAppStore } from '../../../types/IAppStore';
import RestaurantSelect from './RestaurantSelect';
import UserIcon from '../Icons/UserIcon';
import PushIcon from '../Icons/PushIcon';
import PlaceholdersIcon from '../Icons/PlaceholdersIcon';
// import HeaderHelpIcon from '../Icons/HeaderHelpIcon';
// import EditIcon from '../Icons/EditIcon';

import { toggleNotifications } from '../../../actions/notifications/NotificationsActions';
import { toggleAppReserves, addAppReserve } from '../../../actions/appReserves/AppReservesActions';
import { selectorOpenModal } from '../../../containers/Reservation/selectors';
import Reserve from '../../../types/IAppReservesStore';

const styles = (theme: Theme) => createStyles({
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
  },
  ItemWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
  NameStyle: {
    marginRight: '5px',
  },
  restaurantSelectorIcon: {
    display: 'none',
    [theme.breakpoints.down(600)]: {
      display: 'block',
    },
  },
  headerIcon: {
    fontSize: 18,
    [theme.breakpoints.up(600)]: {
      fontSize: 24,
    },
  },
  headerUserIcon: {
    fontSize: 24,
    [theme.breakpoints.up(600)]: {
      fontSize: 32,
    },
  },

  // @todo Проверить стили, размеры
  headerEditIcon: {
    color: '#e7ebee',
    opacity: 0.7,

    fontSize: 20,
    [theme.breakpoints.up(600)]: {
      fontSize: 26,
    },
  },
  badge: {
    color: 'white',
  },

  '@keyframes shake': {
    '10%, 90%': {
      transform: 'translate(9px, -10px)',
    },
    '20%, 80%': {
      transform: 'translate(11px, -10px)',
    },
    '30%, 50%, 70%': {
      transform: 'translate(7px, -10px)',
    },
    '40%, 60%': {
      transform: 'translate(13px, -10px)',
    },
  },

  shake: {
    animation: '$shake 0.82s',
    transform: 'translate(10px, -10px)',
  },
});

interface IDispatchToProps {
  openCreateModal: typeof openCreateModal;
  setCurrentRestaurant: typeof setCurrentRestaurant;
  signOut: typeof signOut;
  toggleNotifications: typeof toggleNotifications;
  toggleAppReserves: typeof toggleAppReserves;
  addAppReserve: typeof addAppReserve;
}

interface IMapsToPops {
  restaurantList: Array<TRestaurant>;
  currentRestaurant?: TRestaurant;
  user?: TUser;
  notificationsOpen: boolean;
  notificationsCount: number;
  appReservesOpen: boolean;
  appReservesCount: number;
  widgetAppReservesCount: number;
}

interface IProps extends
  WithStyles<typeof styles>,
  IDispatchToProps,
  IMapsToPops
{}

interface IState {
  anchorEl: any;
  shakeReserves: boolean;
  shakeNotifications: boolean;
}

class HeaderComp extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      anchorEl: null,
      shakeReserves: false,
      shakeNotifications: false,
    };
  }

  componentDidMount() {
    window.setTimeout(() => {
      this.shakeReservesInterval = window.setInterval(() => {
        this.scheduleShakeReserves();
      }, 10000);
    }, 5000);

    this.shakeNotificationsInterval = window.setInterval(() => {
      this.scheduleShakeNotifications();
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.shakeReservesInterval);
    clearInterval(this.shakeNotificationsInterval);
  }

  handleProfileMenuOpen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  scheduleShakeReserves = () => {
    const { appReservesOpen, widgetAppReservesCount } = this.props;

    if (widgetAppReservesCount > 0 && !appReservesOpen) {
      this.setState({ shakeReserves: true });

      setTimeout(() => {
        this.setState({ shakeReserves: false });
      }, 5000);
    }
  };

  scheduleShakeNotifications = () => {
    const { notificationsCount, notificationsOpen } = this.props;

    if (notificationsCount > 0 && !notificationsOpen) {
      this.setState({ shakeNotifications: true });

      setTimeout(() => {
        this.setState({ shakeNotifications: false });
      }, 5000);
    }
  };

  shakeReservesInterval: number | undefined;

  shakeNotificationsInterval: number | undefined;

  render() {
    const { anchorEl, shakeReserves, shakeNotifications } = this.state;

    const {
      classes,
      user,
      signOut,
      currentRestaurant,
      restaurantList,
      setCurrentRestaurant,
      toggleNotifications,
      notificationsOpen,
      notificationsCount,
      appReservesCount,
      appReservesOpen,
      toggleAppReserves,
      addAppReserve,
    } = this.props;

    const isMenuOpen = Boolean(anchorEl);
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        {/* <MenuItem onClick={this.handleMenuClose}>{ locales.t('common.profile') }</MenuItem> */}
        <MenuItem onClick={signOut}>{ locales.t('common.logOut') }</MenuItem>
      </Menu>
    );

    const reservesBadgeColor = appReservesCount === 0 ? 'secondary' : 'error';
    const notificationBadgeColor = notificationsCount === 0 ? 'secondary' : 'error';

    return (
      <div className={classes.wrap}>
        <RestaurantSelect
          currentRestaurant={currentRestaurant}
          restaurantList={restaurantList}
          setCurrentRestaurant={setCurrentRestaurant}
        />
        <div className={classes.ItemWrap}>
          <IconButton className={classes.restaurantSelectorIcon}>
            <PlaceholdersIcon className={classes.headerIcon} />
          </IconButton>

          {/*
          <IconButton>
            <HeaderHelpIcon className={classes.headerIcon} />
          </IconButton>
          */}

          <Tooltip title={locales.t('common.reserveQueue.appBarTooltip')}>
            <span>
              <IconButton
                // disabled={appReservesCount === 0}
                onClick={() => {
                  if (appReservesCount === 0) {
                    addAppReserve(new Reserve('APP'));
                  } else {
                    toggleAppReserves();
                  }
                }}
              >
                <Badge
                  badgeContent={appReservesCount}
                  color={
                    appReservesCount > 0 && appReservesOpen ? 'primary' : reservesBadgeColor
                  }
                  classes={{
                    colorError: classes.badge,
                    badge: (shakeReserves && classes.shake) || undefined,
                  }}
                  // showZero
                >
                  <EditIcon className={classes.headerEditIcon} />
                </Badge>
              </IconButton>
            </span>
          </Tooltip>

          <IconButton
            disabled={notificationsCount === 0}
            onClick={() => toggleNotifications()}
          >
            <Badge
              badgeContent={notificationsCount}
              color={
                notificationsCount > 0 && notificationsOpen ? 'primary' : notificationBadgeColor
              }
              classes={{
                colorError: classes.badge,
                badge: (shakeNotifications && classes.shake) || undefined,
              }}
              // showZero
            >
              <PushIcon className={classes.headerIcon} />
            </Badge>
          </IconButton>

          <IconButton
            onClick={this.handleProfileMenuOpen}
          >
            {
              user && user.imageUrl
                ? (
                  <Avatar className={classes.headerUserIcon} />
                )
                : (
                  <UserIcon className={classes.headerUserIcon} />
                )
            }
          </IconButton>
        </div>
        { renderMenu }
      </div>
    );
  }
}

const mapStateToProps = (state: IAppStore): IMapsToPops => {
  const reserves = state.appReserves.items || [];

  return {
    restaurantList: state.cabinet.restaurantList,
    currentRestaurant: state.cabinet.currentRestaurant,
    user: state.auth.user,

    notificationsOpen: state.notifications.open,
    notificationsCount: state.notifications.items.length,

    appReservesOpen: !!selectorOpenModal(state),
    appReservesCount: reserves.length || 0,
    widgetAppReservesCount: reserves.filter((item: Reserve) => item.type === 'WIDGET').length || 0,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => bindActionCreators({
  openCreateModal,
  setCurrentRestaurant,
  signOut,
  toggleNotifications,
  toggleAppReserves,
  addAppReserve,
}, dispatch);

const index = withStyles(styles)(HeaderComp);

export default connect(mapStateToProps, mapDispatchToProps)(index);
