import React from 'react';
import {
  withStyles, createStyles, WithStyles, Theme,
} from '@material-ui/core/styles';
import locales from '../../locales';


const styles = (theme: Theme) => createStyles({
  toolbarWarning: {
    position: 'sticky',
    top: 64,
    [theme.breakpoints.down(600)]: {
      top: 56,
    },
    zIndex: 1000,
    width: '100%',
    overflowX: 'hidden',
    display: 'flex',
    flex: 1,
    flexBasis: 64,
    padding: '0 28px 0 16px',
    background: '#faca4e',
    alignItems: 'center',
    boxShadow: 'inset 0 2px 6px -2px rgb(31 48 63 / 50%)',
    color: '#323232',
    fontFamily: 'GolosTextWebMedium, Roboto',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
  },
  toolbarWarningBody: {
    alignItems: 'flex-start',
    flexGrow: 1,
  },
  toolbarWarningContent: {
    fontSize: '14px',
    fontWeight: 'inherit',
    fontStretch: 'inherit',
    fontStyle: 'inherit',
    lineHeight: 1,
    color: 'inherit',
    marginTop: 8,
    marginBottom: 20,
    opacity: 0.7,
  },
  toolbarWarningTitle: {
    fontSize: '16px',
    fontWeight: 500,
    fontStretch: 'inherit',
    fontStyle: 'inherit',
    lineHeight: 1.29,
    color: 'inherit',
    marginTop: 20,
    marginBottom: 8,
  },
  toolbarWarningButton: {
    fontSize: '12px',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    fontStretch: 'inherit',
    fontStyle: 'inherit',
    lineHeight: 1.17,
    color: 'inherit',
    alignSelf: 'center',
    opacity: 0.5,
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',

    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      outline: 'none',
    },
  },
  toolbarWarningDivider: {
    width: '0.5px',
    height: '100%',
    minHeight: '56px',
    margin: '16px 24px',
    opacity: 0.5,
    backgroundColor: '#323232',
  },
});

interface IProps extends WithStyles<typeof styles> {
  onClose: () => void;
  title: string;
  message: string;
}

const PageHeaderWarning: React.FC<IProps> = ({
  classes, onClose, title, message,
}) => (
  <div className={classes.toolbarWarning}>
    <div className={classes.toolbarWarningBody}>
      <h3 className={classes.toolbarWarningTitle}>
        {title}
      </h3>
      <p className={classes.toolbarWarningContent}>
        {message}
      </p>
    </div>
    <div className={classes.toolbarWarningDivider} />
    <button
      type="button"
      className={classes.toolbarWarningButton}
      onClick={onClose}
    >
      {locales.t('common.hide')}
    </button>
  </div>
);

export default withStyles(styles)(PageHeaderWarning);
