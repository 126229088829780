import React from 'react';
import ClassNames from 'classnames';

import {
  withStyles, createStyles, WithStyles,
} from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const styles = () => createStyles({
  wrap: {
    padding: 24,
    width: '100%',
  },
  maxWidth: {
    maxWidth: 1055,
  },
});

interface IProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  fullWidth?: boolean;
  direction?: "column" | "column-reverse" | "row" | "row-reverse";
}

const PageContentWrap: React.FC<IProps> = ({
  classes, children, fullWidth, direction,
}) => (
  <div className={ClassNames(classes.wrap, { [classes.maxWidth]: !fullWidth })}>
    <Grid
      direction={direction || 'row'}
      container
      spacing={3}
    >
      {children}
    </Grid>
  </div>
);

export default withStyles(styles)(PageContentWrap);
