import React, { MouseEvent, useState, useCallback } from 'react';

import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';

import locales from '../../locales';
import { Routers } from '../../config/constants';
import HomeIcon from '../Common/Icons/HomeIcon';
import BookingIcon from '../Common/Icons/BookingIcon';
import CardIcon from '../Common/Icons/SettingsIcon';
import SideBarItem from './SideBarItem';
import StatisticIcon from '../Common/Icons/StatisticIcon';

const styles = createStyles({
  menu: {
    padding: '0px 18px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
    color: '#323232',
  },
  menuHeader: {
    paddingTop: '22px',
    paddingBottom: '15px',
    marginBottom: '10px',
  },
  menuIcon: {
    fontSize: 32,
    color: '#323232',
  },
  newReserveWrap: {
    marginTop: 24,
    width: '100%',
    height: 52,
  },
});

interface IProps extends WithStyles<typeof styles> {
  pathname: string;
  handleSwitchDrawerClose: () => void;
  disabled: boolean;
  handleOpenReserveCreateModal: () => void;
}

interface IState {
  showSubMenu: boolean;
}

/** @description Компонент сайдбара */
const SideBar: React.FC<IProps> = ({
  classes,
  pathname,
  handleSwitchDrawerClose,
  handleOpenReserveCreateModal,
  disabled,
}) => {
  /** @description Стейт видимости сайдбара */
  const [state, setState] = useState<IState>({ showSubMenu: false });

  /**
   * @description Функция проверки адреса подменю на принадлежность разделу "Ресторан"
   * @param pathname
   * @returns {boolean}
   */
  const checkIsRestaurantItem = useCallback((pathname: string) => {
    const restaurantSubMenuRouters = {
      [Routers.reservesSystem]: 'card',
      [Routers.restaurantScheme]: 'card',
      [Routers.cardMenu]: 'card',
      [Routers.cardAction]: 'card',
      [Routers.cardSettings]: 'card',
    };

    return restaurantSubMenuRouters[pathname] === 'card';
  }, []);

  /**
   * @description Ручка для открытия/закрытия подменю
   * @param {Class} event - событие
   */
  const handleSwitchSubMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    const { showSubMenu } = state;

    event.stopPropagation();
    event.preventDefault();
    setState({ showSubMenu: !showSubMenu });
  }, [state]);

  return (
    <List
      className={classes.menu}
    >
      <SideBarItem
        route={Routers.home}
        pathname={pathname}
        handleSwitchDrawerClose={handleSwitchDrawerClose}
        text={locales.t('sideBar.home')}
        icon={<HomeIcon className={classes.menuIcon} />}
      />

      <SideBarItem
        disabled={disabled}
        route={Routers.booking}
        pathname={pathname}
        handleSwitchDrawerClose={handleSwitchDrawerClose}
        text={locales.t('sideBar.booking')}
        icon={<BookingIcon className={classes.menuIcon} />}
      />

      <SideBarItem
        disabled={disabled}
        route={Routers.statistics}
        pathname={pathname}
        handleSwitchDrawerClose={handleSwitchDrawerClose}
        text={locales.t('statistics.title')}
        icon={<StatisticIcon className={classes.menuIcon} />}
      />

      <SideBarItem
        disabled={disabled}
        route={Routers.reservesSystem}
        pathname={pathname}
        showSubMenu={!disabled && state.showSubMenu}
        handleSwitchSubMenu={handleSwitchSubMenu}
        handleSwitchDrawerClose={handleSwitchDrawerClose}
        checkIsRestaurantItem={checkIsRestaurantItem}
        text={locales.t('sideBar.settings')}
        icon={<CardIcon className={classes.menuIcon} />}
        subMenu={disabled ? undefined : [
          { name: locales.t('reservesSystem.title'), route: Routers.reservesSystem },
          { name: locales.t('restaurantSchema.title'), route: Routers.restaurantScheme },
        ]}
      />
      <Button
        disabled={disabled}
        className={classes.newReserveWrap}
        variant="contained"
        color="primary"
        onClick={handleOpenReserveCreateModal}
      >
        { locales.t('sideBar.newReserve') }
      </Button>
    </List>
  );
};

export default withStyles(styles)(SideBar);
