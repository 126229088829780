import React, { useState, useEffect, ReactElement } from 'react';

import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import {
  getCodePhoneNumber,
  getLocale,
  normalizePhone,
  onlyNumbers,
} from '../../helpers/reserveHelpers';
import { phoneCode, getPhoneCodes } from '../../config/constants';

const styles = createStyles({
  fieldWrap: {
    display: 'flex',
    paddingTop: 12,
  },
  phoneField: {
    marginRight: 8,
  },
});

interface IProps extends WithStyles<typeof styles> {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  staticValue: string;
  label: string;
  readOnly: boolean;
  type: string;
  endAdornment: any;
  disabled?: boolean;
  countryCode: string;
  locale?: string;
  required?: boolean;
}

// TODO видимо нужно рефакторить логику переключения локалей
const PhoneFieldComp: React.FC<IProps> = ({
  classes,
  input,
  label,
  type,
  staticValue,
  readOnly,
  endAdornment,
  meta: { touched, error },
  disabled,
  locale,
  required,
}) => {
  const [value, setValue] = useState({
    code: phoneCode.ru.code,
    number: '',
  });

  //
  // useEffect(() => {
  //   if (input.value !== '') {
  //     change({
  //       ...getCodePhoneNumber(input.value),
  //     });
  //   } else {
  //     change({
  //       number: '',
  //       code: locale === 'RU' ? phoneCode.ru.code : phoneCode.ukr.code,
  //     });
  //   }
  // }, []);

  let firstCountryKey: undefined | string;

  useEffect(() => {
    if (input.value !== '') {
      setValue({
        ...getCodePhoneNumber(input.value),
      });
    } else {
      let codeValue;
      switch (locale) {
        case 'UA':
          codeValue = phoneCode.ukr.code;
          break;
        case 'EE':
          codeValue = phoneCode.est.code;
          break;
        case 'UZ':
          codeValue = phoneCode.uz.code;
          break;
        case 'AM':
          codeValue = phoneCode.am.code;
          break;
        case 'GE':
          codeValue = phoneCode.ge.code;
          break;
        case 'KZ':
          codeValue = phoneCode.kz.code;
          break;
        case 'AE':
          codeValue = phoneCode.ae.code;
          break;
        case 'US':
          codeValue = phoneCode.us.code;
          break;
        case 'AZ':
          codeValue = phoneCode.az.code;
          break;
        case 'BY':
          codeValue = phoneCode.by.code;
          break;
        case 'KG':
          codeValue = phoneCode.kg.code;
          break;
        case 'TM':
          codeValue = phoneCode.tm.code;
          break;
        default:
        case 'RU':
          codeValue = phoneCode.ru.code;
          break;
      }

      setValue({
        number: '',
        code: codeValue,
      });

      firstCountryKey = locale && Object.prototype.hasOwnProperty.call(phoneCode, codeValue)
        ? undefined
        : 'ru';
    }
  }, []);

  const onChange = (e: any) => {
    const number = normalizePhone(e.target.value, value.code);

    input.onChange(`${value.code}${onlyNumbers(number)}`);
    setValue({
      ...value,
      [e.target.name]: number,
    });
  };

  const onChangeCode = (e: any) => {
    input.onChange(`${e.target.value}`);

    setValue({
      code: e.target.value,
      number: '', // normalizePhone(value.number, e.target.value),
    });
  };

  const isError = touched && !!error;
  let l: ReactElement = <>{label}</>;

  if (isError) {
    l = error;
  } else if (required) {
    l = (
      <>
        {label}
        <sup style={{ color: '#f45858', fontSize: 13 }}>*</sup>
      </>
    );
  }

  return (
    <FormControl error={isError}>
      <InputLabel>{ l }</InputLabel>

      <div className={classes.fieldWrap}>
        <Select
          name="code"
          onChange={onChangeCode}
          disableUnderline
          disabled={!!staticValue || disabled}
          className={classes.phoneField}
          value={value.code}
          defaultValue={getLocale(locale)}
          renderValue={(code) => {
            switch (code) {
              case phoneCode.ru.code:
                return phoneCode.ru.firstLabel;
              case phoneCode.ukr.code:
                return phoneCode.ukr.firstLabel;
              case phoneCode.est.code:
                return phoneCode.est.firstLabel;
              case phoneCode.uz.code:
                return phoneCode.uz.firstLabel;
              case phoneCode.am.code:
                return phoneCode.am.firstLabel;
              case phoneCode.ge.code:
                return phoneCode.ge.firstLabel;
              case phoneCode.kz.code:
                return phoneCode.kz.firstLabel;
              case phoneCode.ae.code:
                return phoneCode.ae.firstLabel;
              case phoneCode.us.code:
                return phoneCode.us.firstLabel;
              case phoneCode.az.code:
                return phoneCode.az.firstLabel;
              case phoneCode.by.code:
                return phoneCode.by.firstLabel;
              case phoneCode.kg.code:
                return phoneCode.kg.firstLabel;
              case phoneCode.tm.code:
                return phoneCode.tm.firstLabel;
              default:
                return phoneCode.ru.firstLabel;
            }
          }}
        >
          {getPhoneCodes(firstCountryKey).map(phoneCode => (
            <MenuItem value={phoneCode.code} key={phoneCode.key}>
              { phoneCode.secondLabel }
            </MenuItem>
          ))}
        </Select>
        <TextField
          name="number"
          fullWidth
          type={type}
          error={isError}
          onChange={onChange}
          value={value.number}
          disabled={!!staticValue || disabled}
          InputProps={{
            readOnly,
            endAdornment,
          }}
        />
      </div>
    </FormControl>
  );
};

export default withStyles(styles)(PhoneFieldComp);
