import React from 'react';
import Typography from '@material-ui/core/Typography/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button/Button';
import Logo from '../components/Auth/CommonAuth/Logo';
import UseRules from '../components/Auth/CommonAuth/UseRules';
import AuthWrapComp from '../components/Auth/AuthWrapComp';

const styles = {
  contentTitle: {
    marginTop: 34,
    fontSize: 20,
    lineHeight: '24px',
  },
  contentText: {
    marginTop: 34,
    fontSize: 16,
  },
  contentBtn: {
    marginTop: 34,
    height: 46,
    width: 226,
  },
  contentDesc: {
    marginTop: 34,
    width: 226,
  },
};

const ThanksYouPage = ({ classes }) => (
  <AuthWrapComp>
    <Logo text="Подтвердите адрес email" />
    <Typography align="center" className={classes.contentTitle}>
      Регистрация прошла успешно! Мы отправили Вам письмо на email
    </Typography>
    <div className={classes.contentText}>
      <Typography align="center">
        Для подтверждения регистрации и активации учетной записи, перейдите по ссылке из письма. Письмо не пришло?
      </Typography>
      <Typography align="center">
        Проверьте папку Спам и нажмите кнопку &quot;Отправить еще раз&quot;
      </Typography>
    </div>
    <Button variant="contained" color="primary" className={classes.contentBtn}>
      Отправить ещё раз
    </Button>
    <Typography align="center" variant="caption" className={classes.contentDesc}>
      Не получается зарегистрироваться?
      Напишите нам на info@guestme.ru, мы поможем решить проблему
    </Typography>
    <UseRules />
  </AuthWrapComp>
);

export default withStyles(styles)(ThanksYouPage);
