import axios, { AxiosRequestConfig } from 'axios';
import mapFp from 'lodash/fp/map';
import { Moment } from 'moment';
import { useDispatch } from 'react-redux';

import systems from '../config/systems';
import * as ConfigAPI from '../config/api';
import * as ActionTypes from '../actions/auth/AuthActionsTypes';
import { parseDateForReq } from '../helpers/dateHelpers';
import {
  getAccessToken,
  getRefreshToken,
  setTokens,
} from '../helpers/tokensHelpers';
import { getSchema } from '../helpers/scheme/schemeHelpers';
import {
  adapt as widgetAdapt,
  transform as widgetTransform,
} from '../helpers/widget/widgetHelpers';
import { dataProp } from './utils';

import { ITable } from '../types/IRestaurantTablesStore';
import { IScheduleGroup } from '../types/IScheduleStore';
import { TDate } from '../types/IDate';
import { TRestaurantCallState } from '../types/IRestaurantCallsStore';
import {
  TRestaurantSection,
  TRestaurantSectionAdapt,
} from '../types/IRestaurantTablesSchemaStore';
import {
  IWidgetAdapt,
  IWidgetDataAdapt,
} from '../types/IRestaurantWidgetsStore';
import { IRestaurantAddress, TRestaurantPlugin } from '../types/ICabinetStore';

const api = axios.create({ withCredentials: true });

/** @description Проставляем токен для каждого запроса */
api.interceptors.request.use((config: AxiosRequestConfig) => {
  const accessToken = getAccessToken();
  const configResult = { ...config };

  if (accessToken && accessToken !== 'undefined' && config.url !== ConfigAPI.token) {
    configResult.headers.Authorization = `Bearer ${accessToken}`;
  }
  return configResult;
});

/**
 * Запрос на получение обновление токена
 * @param refreshToken
 * @returns {Promise<AxiosResponse<T>>}
 */
export const refreshTokenReq = (refreshToken: string) => {
  const userData = `refresh_token=${refreshToken}&grant_type=refresh_token`;
  const base64BasicKey = btoa(`${systems.clientId}:${systems.secretKey}`);

  return api.post(
    ConfigAPI.token, userData, { headers: { Authorization: `Basic ${base64BasicKey}` } },
  );
};

/** @description В случае 401 ошибки обновляем токен */
api.interceptors.response.use(response => response, (error) => {
  const originalRequest = error.config;

  if (error.response && error.response.status === 401 && originalRequest.url === ConfigAPI.token) {
    const dispatch = useDispatch();

    dispatch(ActionTypes.GET_SESSION_FAIL);
    return Promise.reject(error);
  }

  if (error.response && error.response.status === 401 && !originalRequest._retry) { // eslint-disable-line no-underscore-dangle
    originalRequest._retry = true; // eslint-disable-line no-underscore-dangle
    const refreshToken = getRefreshToken();

    if (refreshToken) {
      return refreshTokenReq(refreshToken).then((res: {
        status: number;
        data: {
          access_token: string;
          refresh_token: string;
        }
      }) => {
        if (res.status === 200) {
          setTokens(res.data.access_token, res.data.refresh_token);
          originalRequest.headers.Authorization = `Bearer ${res.data.access_token}`;

          return axios(originalRequest);
        }
        return null;
      });
    }
    return null;
  }
  return Promise.reject(error);
});


/**
 * Запрос на получение токена
 * @param email
 * @param password
 */
export const tokenReq = (email: string, password: string) => {
  const userData = `username=${email}&password=${password}&grant_type=password`;
  const base64BasicKey = btoa(`${systems.clientId}:${systems.secretKey}`);

  return api.post(
    ConfigAPI.token, userData, { headers: { Authorization: `Basic ${base64BasicKey}` } },
  );
};

/**
 * Запрос на регистрацию нового пользователя ресторатора
 * @param data - данные нового пользователя
 * @returns {Promise<AxiosResponse<T>>}
 */
export const registerRestaurateurReq = (data: any) => {
  const postData = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    password: data.password,
  };

  return api.post(ConfigAPI.registerRestaurateur, postData);
};

/**
 * Подтверждения регистрации по почте
 * @param key
 * @returns {Promise<AxiosResponse<T>>}
 */
export const confirmEmailReq = (key: any) => api.get(ConfigAPI.confirmEmail, {
  params: {
    'activation-key': key,
    'remember-me': true,
  },
});

/**
 * Запрос на получение сессии для авторизированного юзера
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getAccountReq = () => api.get(ConfigAPI.account);

/**
 * Запрос на логаут
 * @returns {Promise<AxiosResponse<T>>}
 */
export const logoutReq = () => api.get(ConfigAPI.logout);

/**
 * Запрос на регистрацию нового ресторана
 * @param payload
 * @returns {Promise<AxiosResponse<T>>}
 */
export const createRestaurantReq = (payload: {
  address: IRestaurantAddress;
  name: string;
}) => {
  const { name, address } = payload;

  const restaurantReqData = {
    name,
    settings: {
      isAutoBookingEnabled: true,
      isSchemasEnabled: false,
      telegramNotificationSettings: {
        isEnabled: false,
      },
    },
    info: {
      site: null,
      phone: null,
    },
    address: {
      country: (address && address.country) || '-',
      countryCode: (address && address.countryCode) || '-',
      city: (address && address.city) || '-',
      street: (address && address.street) || '-',
      home: (address && address.home) || '-',
      point: {
        x: address.coordinates.lat,
        y: address.coordinates.lon,
      },

      // Не используется в реализации с Яндексом, но решили сохранить такую строку.
      // Без передачи параметра бэк падает с ошибкой 500
      placeId: 'Google place id',
    },
  };

  return api.post(
    ConfigAPI.restaurant, restaurantReqData,
  );
};

/**
 * Запрос на редактирование данных ресторана
 * @param restaurantInfo
 * @returns {Promise<AxiosResponse<T>>}
 */
export const updateRestaurantReq = (restaurantInfo: any) => api.put(
  ConfigAPI.restaurant, restaurantInfo,
);

/**
 * Запрос на получение версий плагинов ресторана
 * @param restaurantId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getRestaurantPluginsInfoReq = (restaurantId: number): Promise<TRestaurantPlugin[]> => api.get(`${ConfigAPI.restaurant}/${restaurantId}/plugin`).then(dataProp);

/**
 * Запрос на получение списка столов выбранного ресторана
 * @param restaurantId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getAllTableForRestaurantReq = (restaurantId: any) => {
  const url = `${ConfigAPI.restaurantTable}/${restaurantId}`;
  return api.get(url);
};

/**
 * Запрос на удаление выбранного ресторана
 * @param restaurantId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const deleteRestaurantReq = (restaurantId: any) => api.delete(`${ConfigAPI.restaurant}/${restaurantId}`);

/**
 * Запрос на создание стола для выбранного ресторана
 * @param hwid
 * @param restaurantData
 * @returns {Promise<AxiosResponse<T>>}
 */
export const createTableReq = ({ hwid, restaurantData }: any) => api.post(
  ConfigAPI.restaurantTable,
  restaurantData,
  { headers: { 'K-hwid': hwid } },
);

/**
 * Запрос на редактирования количества мест для выбранного стола
 * @param restaurantId
 * @param tables
 * @returns {Promise<AxiosResponse<T>>}
 */
export const updateTableReq = (restaurantId: any, tables: Array<ITable>) => api.put(
  `${ConfigAPI.restaurantTable}/${restaurantId}`, tables,
);

/**
 * Запрос на получение всех ресторанов для авторизированного юзера
 * @returns {Promise<AxiosResponse<any> | never>}
 */
export const getAllRestaurantReq = () => api.get(ConfigAPI.restaurant).then(res => res.data);

/** @description Запрос на получение информации по всем резервам для выбранного ресторана */
export const getAllBookingsByRestaurantId = (
  id: number,
  dateFrom: Moment,
  dateTo: Moment,
  sort: { orderBy: string, order: string },
  active: boolean,
) => {
  const from = parseDateForReq(dateFrom);
  const to = parseDateForReq(dateTo);

  /** @description Если сортируем по полю status добавляем доп. параметр 'sort=canceled-by,asc' */
  if (sort.orderBy === 'status') {
    return api.get(
      `${ConfigAPI.bookingJournal}/${id}/booking/filter/?from=${from}&to=${to}&active=${active}&sort=date,${sort.order}&sort=canceled-by,asc&scheduled=true`,
    );
  }

  return api
    .get(`${ConfigAPI.bookingJournal}/${id}/booking/filter`, {
      params: {
        from,
        to,
        active,
        sort: `${sort.orderBy},${sort.order}`,
        scheduled: true,
      },
    });
};

/** @description Запрос на получение информации по всем резервам для выбранного ресторана */
export const getUnconfirmedReserves = (restaurantId: number) => api
  .get(`${ConfigAPI.bookingJournal}/${restaurantId}/booking/filter`, {
    params: {
      status: 'CREATED',
    },
  }).then(r => r.data);

/**
 * Список резервов, требующих подтверждения (резервы, которые поступили из виджета)
 * @return TOpenReserves
 */
export const getOpenReserves = (restaurantId: number) => api
  .get(`${ConfigAPI.bookingJournal}/${restaurantId}/booking/confirmation`)
  .then(r => r.data);

/** @description Запрос на получение информации по всем резервам для выбранного ресторана */
export const getAllBookingsByRestaurantIdWithPageable = (
  id: number,
  date: {
    from: Moment,
    to: Moment,
  },
  sort: { orderBy: string, order: string },
  page: number,
  size: number,
) => {
  const from = parseDateForReq(date.from);
  const to = parseDateForReq(date.to);

  return api
    .get(`${ConfigAPI.bookingJournal}/${id}/booking/filter/pageable`, {
      params: {
        page,
        size,
        from,
        to,
        sort: `${sort.orderBy},${sort.order}`,
        scheduled: true,
        final: true,
      },
    }).then(res => res.data);
};

/** @description Запрос на получение списка резервов по номеру телефона юзера */
export const getAllBookingsByUserPhone = (
  id: number,
  phone: number,
  sort: { orderBy: string, order: string },
) => api
  .get(`${ConfigAPI.bookingJournal}/${id}/booking/filter`, {
    params: {
      sort: `${sort.orderBy},${sort.order}`,
      scheduled: true,
      final: true,
      'guest-phone': phone,
    },
  }).then(res => res.data);

/** @description Запрос на получение списка резервов по выбранному источнику */
export const getAllBookingBySource = (
  id: number,
  source: string,
  period: { from: string, to: string },
  sort: { orderBy: string, order: string },
  size: number,
  page: number,
) => api
  .get(`${ConfigAPI.bookingJournal}/${id}/booking/filter/pageable`, {
    params: {
      from: parseDateForReq(period.from),
      to: parseDateForReq(period.to),
      sort: `${sort.orderBy},${sort.order}`,
      scheduled: true,
      final: true,
      page,
      size,
      'source-id': source,
    },
  }).then(res => res.data);

/**
 * Запрос на получение статистики для выбранного ресторана по параметрам
 * @param id - айди ресторана
 * @param date
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getRestaurantStatistic = (id: any, date: TDate) => api
  .get(`${ConfigAPI.statistic}/${id}/booking/statistic`, {
    params: {
      from: parseDateForReq(date.from),
      to: parseDateForReq(date.to),
    },
  });


/**
 * Запрос на получение сводной статистики
 * @param restaurantId
 * @param period
 */
export const getSummaryStatistic = (restaurantId: number, period: { from: string, to: string }) => api
  .get(`${ConfigAPI.statisticSummary}/${restaurantId}/booking/statistic/summary`, {
    params: {
      from: period.from,
      to: period.to,
    },
  }).then(res => res.data);

/**
 * Запрос на получение статистики по источникам
 * @param restaurantId
 * @param period
 */
export const getStatisticPie = (restaurantId: number, period: { from: string, to: string }) => api
  .get(`${ConfigAPI.statisticPie}/${restaurantId}/booking/statistic/source/count`, {
    params: {
      from: period.from,
      to: period.to,
    },
  }).then(res => res.data);

/**
 * @description Запрос на получение статистики гостя
 * @param restaurantId
 * @param userPhone
 */
export const getGuestStatistic = (
  restaurantId: number, userPhone: number,
) => api
  .get(`${ConfigAPI.statisticGuest}/${restaurantId}/booking/statistic/guest`, {
    params: {
      'guest-phone': userPhone,
    },
  })
  .then(res => res.data);

/**
 * @description Запрос на получение информации пользователя по номеру телефона
 * @param userPhone
 */
export const getUserInfo = (userPhone: number) => api
  .get(`${ConfigAPI.userInfo}/${userPhone}`)
  .then(res => res.data);

/**
 * @description Запрос на получение информации о пользователе
 * @param userPhone
 * @param restaurantId
 */
export const getUserVisitInfo = (userPhone: string, restaurantId: number) => api
  .get(`${ConfigAPI.statistic}/${restaurantId}/booking/statistic/guest/info`, {
    params: {
      'guest-phone': userPhone,
    },
  })
  .then(res => res.data);

/**
 * @description Запрос на получение статистики по источнику
 * @param restaurantId
 * @param period
 * @param source
 */
export const getSourceStatisticReq = (restaurantId: number, period: { from: string; to: string }, source: string) => api
  .get(`${ConfigAPI.statistic}/${restaurantId}/booking/statistic/source`, {
    params: {
      from: period.from,
      to: period.to,
      'source-id': source,
    },
  }).then(res => res.data);

/**
 * Запрос на получение загруженности ресторатора
 * @param id - id ресторана
 * @param from - дата с
 * @param to - дата по
 * @param period - период
 * @return {Promise<AxiosResponse<T>>}
 */
export const getRestaurantAttendance = (id: any, from: any, to: any, period: any) => api
  .get(`${ConfigAPI.statistic}/${id}/booking/statistic/attendance`, {
    params: {
      from: parseDateForReq(from),
      to: parseDateForReq(to),
      period,
    },
  });

/**
 * Запрос на создание нового резерва для выбранного ресторана
 * @param date - дата резерва
 * @param duration - предположительное время резерва - по дефолту 2 часа
 * @param firstName - имя гостя
 * @param lastName - фамилия гостя - не обязательное поле
 * @param persons - количество персон
 * @param phone - номер телефона
 * @param tables - массив айдишников столов
 * @param comment
 * @param restaurantId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const createReserveReq = ({
  date,
  duration,
  firstName,
  persons,
  phone,
  tables,
  comment,
  isBanquet,
}: any, restaurantId: any) => {
  const reqData = {
    date: parseDateForReq(date),
    duration: parseInt(duration) || 2,
    firstName,
    persons,
    phone,
    comment,
    tables,
    isBanquet: !!isBanquet,
  };

  return api.post(`${ConfigAPI.restaurateur}/restaurant/${restaurantId}/booking`, reqData);
};

/**
 * @description Запрос на получение доступных столов для резерва по выбранным параметрам
 * @param restaurantId
 * @param date - дата
 * @param persons - количество персон
 * @param duration - предположительное время резерва - по дефолту 2 часа
 * @param booking - id резерва параметр нужен для запроса столов при редактировании резерва
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getFreeTablesReq = (
  restaurantId: number, date: string, persons: number, duration: number = 2, booking?: number,
) => api.get(`${ConfigAPI.restaurantTable}/${restaurantId}/free`, {
  params: {
    time: parseDateForReq(date),
    persons,
    duration,
    booking,
  },
});

const adaptSection = (data: TRestaurantSection): TRestaurantSectionAdapt => ({
  ...data,
  schema: data.schema ? getSchema(data.schema) : undefined,
});
/**
 * @description Запрос на получение списка залов
 * @param restaurantId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getSectionTablesReq = (
  restaurantId: number,
) => api.get(`${ConfigAPI.restaurant}/${restaurantId}/section`)
  .then(res => res.data).then(mapFp(adaptSection));

/**
 * Запрос на получение информации бронирования
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getReserveInfo = (id: number) => api.get(`${ConfigAPI.restaurateurBooking}/${id}`)
  .then(res => res.data);

/**
 * Запрос на отмену резерва
 */
export const sendRejectReserveRequest = (id: number) => api
  .delete(`${ConfigAPI.restaurateurBooking}/${id}`)
  .then(r => r.data);

/**
 * Редактирование резерва
 * @param {number} id - ID Резерва
 * @param {Object} info - Новая информация
 */
export const patchReserveReq = (id: number, info: any) => api
  .patch(`${ConfigAPI.restaurateurBooking}/${id}`, { ...info })
  .then(r => r.data);

/**
 * Подтверждение нового резерва
 */
export const confirmReserveReq = (id: number) => api
  .put(`${ConfigAPI.restaurateurBooking}/${id}/confirm`)
  .then(r => r.data);

/**
 * Авторизация ресторана в iiko
 * @param {number} restaurantId
 * @param {{login: string, password: string}} authData
 * @returns {Promise<AxiosResponse<T>>}
 */
export const authIikoReq = (restaurantId: number, authData: any) => api
  .patch(`${ConfigAPI.restaurant}/${restaurantId}/iiko/credentials`, authData);

/**
 * Получения списка организаций из iiko
 * @param restaurantId
 * @returns {Promise<AxiosResponse<any> | never>}
 */
export const getOrganizationFromIiko = (restaurantId: number) => api
  .get(`${ConfigAPI.restaurant}/${restaurantId}/iiko/organizations`)
  .then(res => res.data);

/**
 * Выбор организации для выбранного ресторана
 * @param {number} restaurantId
 * @param {string} organizationId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const selectOrganizationReq = (restaurantId: number, organizationId: string) => api
  .patch(`${ConfigAPI.restaurant}/${restaurantId}/iiko/organizations`, {
    id: organizationId,
  });

/**
 * Проверка привязки ресторана к иико
 * @param restaurantId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const checkRestaurantFromIikoReq = (restaurantId: number) => api
  .get(`${ConfigAPI.restaurant}/${restaurantId}/iiko/credentials`);

/**
 * Загрузка акций с нашего бекенда
 * @param restaurantId
 * @returns {Promise<AxiosResponse<any> | never>}
 */
export const getProgramsReq = (restaurantId: number) => api
  .get(`${ConfigAPI.programs}/restaurant/${restaurantId}`)
  .then(res => res.data);

/**
 * Загрузка акций с бекенда iiko
 * @param restaurantId
 * @returns {Promise<AxiosResponse<any> | never>}
 */
export const loadProgramsReq = (restaurantId: number) => api
  .post(`${ConfigAPI.loadsPrograms}/${restaurantId}/iiko/load`)
  .then(res => res.data);

/**
 * Запрос на получение списка расписания по группам.
 * @param restaurantId
 */
export const getScheduleGroupReq = (restaurantId: number) => api
  .get(ConfigAPI.restaurantScheduleGroup, {
    params: {
      restaurantId,
    },
  })
  .then(r => r.data);

/**
 * Запрос на создания новой группы расписания.
 * @param groups
 */
export const createScheduleGroupReq = (groups: Array<IScheduleGroup>) => api
  .post(ConfigAPI.restaurantScheduleGroups, groups)
  .then(r => r.data);

/**
 * Запрос на редактирование группы расписания.
 * @param groups
 */
export const updateScheduleGroupReq = (groups: Array<IScheduleGroup>) => api
  .put(ConfigAPI.restaurantScheduleGroups, groups)
  .then(r => r.data);

/**
 * Запрос на удаление группы расписания.
 * @param ids
 */
export const deleteScheduleGroupReq = (ids: Array<number>) => api
  .delete(ConfigAPI.restaurantScheduleGroups, { data: { ids } });

/**
 * Загрузка меню
 * @param restaurantId
 * @returns {Promise<AxiosResponse<any> | never>}
 */
export const getRestaurantMenuReq = (restaurantId: number) => api
  .get(`${ConfigAPI.menuRestaurant}/${restaurantId}`)
  .then(res => res.data);

/**
 * Запрос на загрузку меню из iiko
 * @param restaurantId
 * @returns {Promise<AxiosResponse<any> | never>}
 */
export const getRestaurantMenuFromIikoReq = ({ restaurantId }: any) => api
  .post(`${ConfigAPI.menuRestaurant}/${restaurantId}/iiko/load`)
  .then(res => res.data);

/**
 * Переключение режима приёма броней
 * @param restaurantId
 * @param processorType
 * @returns {Promise<AxiosResponse<any> | never>}
 */
export const switchBookingProcessorReq = ({
  restaurantId,
  processorType,
}: any) => api
  .patch(`${ConfigAPI.restaurant}/${restaurantId}/reservation/processor`, { reserveProcessor: processorType })
  .then(res => res.data);

/**
 * Проверка статуса "Подключение к системе автоматизации"
 * @param restaurantId
 * @returns {Promise<AxiosResponse<any> | never>}
 */
export const cashBoxConnectStatusReq = (
  restaurantId: number,
) => api.get(`${ConfigAPI.restaurant}/${restaurantId}/plugin/status`)
  .then(res => res.data);


/**
 * Получение пинкода ресторана
 * @param restaurantId
 * @returns {Promise<AxiosResponse<any> | never>}
 */
export const getRestaurantPinCodeReg = (
  restaurantId: number,
) => api.get(`${ConfigAPI.restaurant}/${restaurantId}/pinCode`);

/**
 * @description Отправка запроса на восстановление пароля
 * @param {string} email
 * @return {Promise<AxiosResponse<T>>}
 */
export const restorePasswordReq = (email: string) => api.post(ConfigAPI.restorePasswordInit, email, {
  headers: {
    'Content-Type': 'text/plain',
  },
});

/**
 * @description Отправка нового пароля
 * @param key
 * @param newPassword
 * @return {Promise<AxiosResponse<T>>}
 */
export const sendNewPasswordReq = (key: string, newPassword: string) => api
  .post(ConfigAPI.restorePasswordFinish, {
    key,
    newPassword,
  });

/** @description Получение изборажений ресторана */
export const getImageReq = (restaurantId: number) => api
  .get(`${ConfigAPI.restaurant}/${restaurantId}/image`)
  .then((res: any) => res.data);

/** @description Загрузка нового изображения */
export const uploadImageReq = (restaurantId: number, image: any) => {
  const formData = new FormData();
  formData.append('image', image);

  // До лучших времен
  // for (const image of images) {
  //   formData.append('image', image);
  // }

  return api
    .put(
      `${ConfigAPI.restaurant}/${restaurantId}/image/upload`,
      formData,
      {
        headers: {
          'Content-Type': image.type,
        },
      },
    );
};

/** @description Скрыть изображение */
export const hideImageReq = (
  restaurantId: number,
  imageId: number,
) => api
  .put(`${ConfigAPI.restaurant}/${restaurantId}/image/${imageId}/hide`);

/** @description Показать изображение */
export const showImageReq = (
  restaurantId: number,
  imageId: number,
) => api
  .put(`${ConfigAPI.restaurant}/${restaurantId}/image/${imageId}/show`);

/** @description Реквест на установку фото в качестве обложки */
export const setImageAsCoverReq = (
  restaurantId: number,
  imageId: number,
) => api
  .put(`${ConfigAPI.restaurant}/${restaurantId}/image/${imageId}/main`);

/** @description Создание виджета */
export const createWidgetReq = (restaurantId: number, data: IWidgetDataAdapt): Promise<IWidgetAdapt> => api
  .post(`${ConfigAPI.restaurantWidget}/restaurant/${restaurantId}`, widgetTransform(data))
  .then(dataProp)
  .then(widgetAdapt);

/** @description Получение списка всех виджетов ресторана по id */
export const getAllWidgetsReq = (restaurantId: number): Promise<IWidgetAdapt[]> => api
  .get(`${ConfigAPI.restaurantWidget}/restaurant/${restaurantId}`)
  .then(dataProp)
  .then(mapFp(widgetAdapt));

/** @description Удаление виджета по id */
export const deleteWidgetReq = (widgetId: number) => api
  .delete(`${ConfigAPI.restaurantWidget}/${widgetId}`);

/** @description Редактирование поля description виджета по id */
export const updateWidgetReq = (widgetId: number, data: IWidgetDataAdapt): Promise<IWidgetAdapt> => api
  .put(`${ConfigAPI.restaurantWidget}/${widgetId}`, widgetTransform(data))
  .then(dataProp)
  .then(widgetAdapt);

/** @description Реквест для получения списка блюд для резерва */
export const getOrderByBookingIdReq = (bookingId: number) => api
  .get(`${ConfigAPI.restaurateurBooking}/${bookingId}/order/item`)
  .then(r => r.data);

// Получение текущих звонков ресторана
// @todo Работа с постраничной навигацией не реализована в данной итерации.
//   Считаем, что для вывода текущих вызовов достаточно одного запроса
export const getRestaurantCallsReq = (
  restaurantId: number,
  states: Array<TRestaurantCallState>,
  page: number = 0,
  size: number = 20,
) => {
  const queryStates = states.map(state => `&state=${state}`).join('');

  return api.get(
    `${ConfigAPI.restaurantCalls}/${restaurantId}/call?page=${page}&size=${size}${queryStates}`,
  ).then(r => r.data);
};

export const getCashboxStatusRequest = (payload: GM.Cashbox.Request.Status) => {
  return api.get(
    `${ConfigAPI.restaurantCalls}/${payload.restaurantId}/plugin`,
  ).then(
    (r: GM.Cashbox.Response.Status.Success) => r,
  );
};

export const getCashboxDeleteRequest = (payload: GM.Cashbox.Request.Delete) => {
  return api.delete(
    `${ConfigAPI.restaurantCalls}/${payload.restaurantId}/plugin/${payload.hwid}`,
  ).then(
    (r: GM.Cashbox.Response.Delete.Success) => r,
  );
};
