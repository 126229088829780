import * as ActionTypes from '../actions/auth/AuthActionsTypes';
import { IAuthStore } from '../types/IAuthStore';
import { IAppActions } from '../types/IAppActions';

export const initState: IAuthStore = {
  user: undefined,
  error: undefined,
  loading: false,
  getSessionProcess: true,
  confirmEmailProcess: true,
  confirmEmailStatus: false,
  authorized: false,
};

export default function AuthReducer(state = initState, action: IAppActions): IAuthStore {
  switch (action.type) {
    case ActionTypes.SIGN_UP_REQUEST:
    case ActionTypes.SIGN_IN_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case ActionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        error: undefined,
        getSessionProcess: false,
        authorized: true,
      };

    case ActionTypes.SIGN_UP_ERROR:
    case ActionTypes.SIGN_IN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case ActionTypes.SIGN_OUT:
      return {
        ...state,
        user: undefined,
        authorized: false,
      };

    case ActionTypes.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        confirmEmailStatus: true,
        confirmEmailProcess: false,
      };

    case ActionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        error: undefined,
      };

    case ActionTypes.CONFIRM_EMAIL_ERROR:
      return {
        ...state,
        confirmEmailStatus: false,
        confirmEmailProcess: false,
      };

    case ActionTypes.GET_SESSION_REQUEST:
      return {
        ...state,
        getSessionProcess: true,
      };

    case ActionTypes.GET_SESSION_FAIL:
      return {
        ...state,
        getSessionProcess: false,
        authorized: false,
      };

    case ActionTypes.GET_SESSION_SUCCESS:
      return {
        ...state,
        getSessionProcess: false,
        authorized: true,
      };

    default:
      return state;
  }
}
