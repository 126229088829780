import React from 'react';

import Button from '@material-ui/core/Button';

export const errorSnackBar = () => ({
  key: new Date().getTime() + Math.random(),
  options: {
    variant: 'error',
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    action: <Button size="small">Скрыть</Button>,
  },
});

export const messageSnackBar = () => ({
  key: new Date().getTime() + Math.random(),
  options: {
    variant: 'info',
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    action: <Button size="small">Скрыть</Button>,
  },
});

export const customSnackBar = (params: {
  message: string;
  variant?: string;
  vertical?: string;
  horizontal?: string;
}) => ({
  key: new Date().getTime() + Math.random(),
  message: params.message || 'Создан новый резерв',
  options: {
    variant: params.variant || 'info',
    anchorOrigin: {
      vertical: params.vertical || 'top',
      horizontal: params.horizontal || 'right',
    },
    action: <Button size="small">Скрыть</Button>,
  },
});
