export const INIT_START = 'INIT_START';
export const INIT_END = 'INIT_END';

export const GET_RESTAURANT_LIST_REQUEST = 'GET_RESTAURANT_LIST_REQUEST';
export const GET_RESTAURANT_LIST_SUCCESS = 'GET_RESTAURANT_LIST_SUCCESS';
export const GET_RESTAURANT_LIST_ERROR = 'GET_RESTAURANT_LIST_ERROR';

export const CREATE_RESTAURANT_REQUEST = 'CREATE_RESTAURANT_REQUEST';
export const CREATE_RESTAURANT_SUCCESS = 'CREATE_RESTAURANT_SUCCESS';
export const CREATE_RESTAURANT_ERROR = 'CREATE_RESTAURANT_ERROR';

export const SET_CURRENT_RESTAURANT = 'SET_CURRENT_RESTAURANT';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const SHOW_WARNING = 'SHOW_WARNING';
export const HIDE_WARNING = 'HIDE_WARNING';

export const UPDATE_RESTAURANT_LIST = 'UPDATE_RESTAURANT_LIST';

export const OPEN_CONFIRM_RESERVE_MODAL = 'OPEN_CONFIRM_RESERVE_MODAL';
export const CLOSE_CONFIRM_RESERVE_MODAL = 'CLOSE_CONFIRM_RESERVE_MODAL';

export const SWITCH_IIKO_MODAL = 'SWITCH_IIKO_MODAL';
export const CHECK_RESTAURANT_FROM_IIKO_REQUEST = 'CHECK_RESTAURANT_FROM_IIKO_REQUEST';
export const CHECK_RESTAURANT_FROM_IIKO_ERROR = 'CHECK_RESTAURANT_FROM_IIKO_ERROR';
export const NEED_AUTH_FROM_IIKO = 'NEED_AUTH_FROM_IIKO';

export const CASHBOX_CONNECT_STATUS_REQUEST = 'CASHBOX_CONNECT_STATUS_REQUEST';
export const CASHBOX_CONNECT_STATUS_SUCCESS = 'CASHBOX_CONNECT_STATUS_SUCCESS';
export const CASHBOX_CONNECT_STATUS_ERROR = 'CASHBOX_CONNECT_STATUS_ERROR';

export const CASHBOX_PLUGIN_STATUS_REQUEST = 'CASHBOX_PLUGIN_STATUS_REQUEST';
export const CASHBOX_PLUGIN_STATUS_SUCCESS = 'CASHBOX_PLUGIN_STATUS_SUCCESS';
export const CASHBOX_PLUGIN_STATUS_ERROR = 'CASHBOX_PLUGIN_STATUS_ERROR';

export const SWITCH_CUSTOM_DATE_MODAL = 'SWITCH_CUSTOM_DATE_MODAL';
export const CHANGE_CUSTOM_DATE = 'CHANGE_CUSTOM_DATE';

export const SWITCH_AUDIO_NOTIFICATION = 'SWITCH_AUDIO_NOTIFICATION';
