import _ from 'lodash';
import moment from 'moment';

import {
  AUTH_FORM_IIKO_SUCCESS,
  SELECT_ORGANIZATION_SUCCESS,
} from '../actions/home/HomeActionTypes';
import { DELETE_RESTAURANT_SUCCESS } from '../actions/restaurant/RestaurantsActionTypes';
import {
  INIT_END,
  CASHBOX_CONNECT_STATUS_SUCCESS,
  CASHBOX_PLUGIN_STATUS_SUCCESS,
  CHANGE_CUSTOM_DATE,
  CLOSE_MODAL,
  HIDE_WARNING,
  CREATE_RESTAURANT_ERROR,
  GET_RESTAURANT_LIST_ERROR,
  GET_RESTAURANT_LIST_SUCCESS,
  NEED_AUTH_FROM_IIKO,
  OPEN_MODAL,
  SHOW_WARNING,
  SET_CURRENT_RESTAURANT,
  SWITCH_CUSTOM_DATE_MODAL,
  SWITCH_IIKO_MODAL,
  UPDATE_RESTAURANT_LIST,
} from '../actions/cabinet/CabinetActionsTypes';
import { ICabinetStore } from '../types/ICabinetStore';
import { IAppActions } from '../types/IAppActions';
import { dashBoardDates } from '../config/constants';
import { parseDateForStatisticReq } from '../helpers/dateHelpers';
import { getMoneySymbol } from '../helpers/helpers';

export const initState: ICabinetStore = {
  restaurantList: [],
  audioNotification: true,
  error: undefined,
  createRestaurantError: false,
  currentRestaurant: undefined,
  moneySymbol: '₽',
  isInit: false,
  modalOpen: false,
  modalAuthIikoOpen: false,
  warningOpen: false,
  iikoAuth: {
    needAuthFromIiko: false,
    organizationList: [],
    step: 1,
  },
  cashBoxStatus: undefined,
  cashBoxPlugins: undefined,
  bookingQueue: [],
  StatisticCustomDateModalOpen: false,
  StatisticDate: {
    type: dashBoardDates.today,
    value: parseDateForStatisticReq(moment()),
  },
};

// TODO Нужно перенести логику лоадера в отдельный редюсер
export default function reducer(state: ICabinetStore = initState, action: IAppActions): ICabinetStore {
  switch (action.type) {
    case INIT_END: {
      return {
        ...state,
        isInit: true,
      };
    }

    case GET_RESTAURANT_LIST_SUCCESS: {
      const currentRestaurant = action.payload.length > 0 ? action.payload[0] : undefined;

      return {
        ...state,
        restaurantList: action.payload,
        currentRestaurant,
        moneySymbol: getMoneySymbol(currentRestaurant ? currentRestaurant.address.countryCode : 'RU'),
        error: undefined,
      };
    }

    case GET_RESTAURANT_LIST_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    case CREATE_RESTAURANT_ERROR: {
      return {
        ...state,
        createRestaurantError: true,
      };
    }

    case SET_CURRENT_RESTAURANT: {
      const restaurant = _.find(state.restaurantList, { id: action.payload.restaurantId });

      return {
        ...state,
        iikoAuth: {
          needAuthFromIiko: restaurant ? !restaurant.settings.iikoOrganizationId : true,
          organizationList: [],
          step: 1,
        },
        currentRestaurant: restaurant,
        moneySymbol: getMoneySymbol(restaurant ? restaurant.address.countryCode : 'RU'),
      };
    }

    case OPEN_MODAL:
      return {
        ...state,
        modalOpen: true,
      };

    case UPDATE_RESTAURANT_LIST:
      return {
        ...state,
        restaurantList: state.restaurantList.concat(action.payload),
      };

    case CLOSE_MODAL:
      return {
        ...state,
        modalOpen: false,
      };

    case SELECT_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        modalAuthIikoOpen: !state.modalAuthIikoOpen,
        iikoAuth: {
          ...state.iikoAuth,
          needAuthFromIiko: false,
        },
      };
    }

    case SWITCH_IIKO_MODAL: {
      return {
        ...state,
        modalAuthIikoOpen: !state.modalAuthIikoOpen,
      };
    }

    case NEED_AUTH_FROM_IIKO: {
      return {
        ...state,
        iikoAuth: {
          ...state.iikoAuth,
          needAuthFromIiko: true,
        },
      };
    }

    case AUTH_FORM_IIKO_SUCCESS: {
      return {
        ...state,
        iikoAuth: {
          needAuthFromIiko: true,
          organizationList: action.payload,
          step: 2,
        },
      };
    }

    case CASHBOX_CONNECT_STATUS_SUCCESS: {
      return {
        ...state,
        cashBoxStatus: action.payload.active,
      };
    }

    case CASHBOX_PLUGIN_STATUS_SUCCESS: {
      return {
        ...state,
        cashBoxPlugins: action.payload,
      };
    }

    case SHOW_WARNING:
      return {
        ...state,
        warningOpen: true,
      };

    case HIDE_WARNING: {
      return {
        ...state,
        warningOpen: false,
      };
    }

    case DELETE_RESTAURANT_SUCCESS: {
      return {
        ...state,
        currentRestaurant: undefined,
        cashBoxStatus: undefined,
        cashBoxPlugins: undefined,
      };
    }

    case SWITCH_CUSTOM_DATE_MODAL: {
      return {
        ...state,
        StatisticCustomDateModalOpen: !state.StatisticCustomDateModalOpen,
      };
    }

    case CHANGE_CUSTOM_DATE: {
      return {
        ...state,
        StatisticDate: action.payload,
      };
    }

    default:
      return state;
  }
}
