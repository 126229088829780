import React, { Fragment, PureComponent } from 'react';

import DeleteRestaurantMessages from '../../components/ReservesSystem/DeleteRestaurant/DeleteRestaurantMessages';
import DeleteRestaurantModal from '../../components/ReservesSystem/DeleteRestaurant/DeleteRestaurantModal';
import { IProps, IState } from './IDeleteRestaurantCont';

class DeleteRestaurant extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  /** @description Вкл/Выкл моадльного окна для удаления ресторана */
  handleSwitchModal = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  /** @description Удаление выбранного ресторана */
  handleDeleteRestaurant = () => {
    const {
      currentRestaurant, deleteRestaurant,
    } = this.props;

    if (currentRestaurant) {
      deleteRestaurant(currentRestaurant.id);
      this.handleSwitchModal();
    }
  };

  render() {
    const { isOpen } = this.state;

    return (
      <Fragment>
        <DeleteRestaurantMessages
          handleDeleteRestaurant={this.handleSwitchModal}
        />
        <DeleteRestaurantModal
          isOpen={isOpen}
          handleDeleteRestaurant={this.handleDeleteRestaurant}
          handleCloseModal={this.handleSwitchModal}
        />
      </Fragment>
    );
  }
}

export default DeleteRestaurant;
