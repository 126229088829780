import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter as Router } from 'connected-react-router';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import 'typeface-roboto';

import history from '../../redux/history';
import store from '../../redux';
import theme from '../../theme';
import AppComp from './AppComp';

const queryClient = new QueryClient();

const App = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <AppComp />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Router>
    </QueryClientProvider>
  </Provider>
);

export default App;
