import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { IProps, IState } from './ICabinetPage';
import { Routers } from '../../config/constants';
import { ReservesSystemPage, RestaurantSchemePage } from '../../pages/ReservesSystemPage';
import HomeCont from '../../containers/Home';
import BookingCont from '../../containers/Booking';
import StatisticsCont from '../../containers/Statistics';
import AddRestaurantModal from '../../components/Common/CreateRestaurantModal';
import Preloader from '../../components/Common/Preloader';
import UserStatisticCont from '../../containers/UserStatistic';
import SourceStatisticCont from '../../containers/SourceStatistic';
import AuthIkkoModalCont from '../../containers/AuthIikoModalCont';
import DrawerAndHeaderComp from '../../components/Common/DrawerAndHeader/DrawerAndHeaderComp';
import Notifier from '../../components/Common/Snackbar/Notifier';
import NotFoundPage from '../../pages/NotFoundPage';
import SocketErrorScreen from '../../containers/SocketErrorScreenCont';
import styles from './styles';
import ReservationsPage from '../../pages/ReservationsPage';
import Notifications from '../../components/Common/Notifications';
import Reserve from '../../types/IAppReservesStore';
import { IRestaurantCall } from '../../types/IRestaurantCallsStore';

class CabinetPage extends Component<IProps, IState> {
  componentDidMount() {
    this.isInit();

    document.addEventListener('mousemove', this.resetIdleTimer);
    document.addEventListener('click', this.resetIdleTimer);
    document.addEventListener('keypress', this.resetIdleTimer);

    this.resetIdleTimer();
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.resetIdleTimer);
    document.removeEventListener('click', this.resetIdleTimer);
    document.removeEventListener('keypress', this.resetIdleTimer);

    window.clearInterval(this.interval);
  }

  /**
   * @description Инициализация ЛК
   * - Инициализация ЛК
   * - Подключение по веб-сокету
   */
  isInit = () => {
    const { initStarting } = this.props;

    initStarting();
  };

  resetIdleTimer = () => {
    const { idle } = this.props;

    window.clearInterval(this.interval);
    this.interval = window.setInterval(idle, 10000);
  };

  interval: number | undefined;

  render() {
    const {
      classes,
      isInit,
      loading,
      modalOpen,
      cashBoxStatus,
      needAuthFromIiko,
      modalAuthIikoOpen,
      reservationsIsOpen,
      withoutRestaurants,
      pathName,

      notifications,
      notificationsOpen,

      removeNotification,
      toggleNotification,

      removeRestaurantCall,
      confirmOpenReserve,
      rejectOpenReserve,

      addAppReserve,
      calls,
    } = this.props;

    if (!isInit) {
      return <Preloader loading />;
    }

    return (
      <div className={classes.root}>
        <DrawerAndHeaderComp
          isRegistrationCompleted={!(withoutRestaurants || !cashBoxStatus)}
          pathname={pathName}
          handleOpenReserveCreateModal={() => addAppReserve(new Reserve('APP'))}
          reservationsIsOpen={reservationsIsOpen}
        />

        <Notifier />

        {notificationsOpen && notifications && (
          <Notifications
            items={notifications}
            onToggle={(key: string) => { toggleNotification(key); }}

            onRemoveCall={(notificationKey: string, itemId: number) => {
              removeRestaurantCall(itemId, true);
            }}

            onCreateReserveFromCall={(notificationKey: string, itemId: number) => {
              const call = calls && calls.find((i: IRestaurantCall) => i.id === itemId);

              if (call) {
                removeNotification(notificationKey);

                addAppReserve(new Reserve('WIDGET', {
                  phone: call.phone,
                  firstName: call.guestFirstName,
                  lastName: call.guestLastName,
                }));
              }
            }}

            onRemoveReserve={(notificationKey: string, itemId: number) => {
              rejectOpenReserve(itemId);
            }}

            onConfirmReserve={(notificationKey: string, itemId: number) => {
              confirmOpenReserve(itemId);
            }}

            onEditReserve={(notificationKey, reserve: Reserve) => {
              removeNotification(notificationKey);

              addAppReserve(reserve);
            }}
          />
        )}

        <Preloader loading={loading} />
        <SocketErrorScreen />
        <main className={classes.content}>
          {
            reservationsIsOpen
              ? <ReservationsPage />
              : (
                <Fragment>
                  <Switch>
                    <Route exact path={Routers.main} component={HomeCont} />
                    <Route path={Routers.home} component={HomeCont} />
                    <Route path={Routers.booking} component={BookingCont} />
                    <Route path={Routers.reservesSystem} component={ReservesSystemPage} />
                    <Route path={Routers.restaurantScheme} component={RestaurantSchemePage} />
                    <Route path={Routers.statistics} component={StatisticsCont} />
                    <Route path={`${Routers.userStatistics}/:phone`} component={UserStatisticCont} />
                    <Route path={`${Routers.sourceStatistics}/:source`} component={SourceStatisticCont} />
                    <Route component={NotFoundPage} />
                  </Switch>
                  {modalOpen ? <AddRestaurantModal /> : null}
                  {needAuthFromIiko && modalAuthIikoOpen ? <AuthIkkoModalCont /> : null}
                </Fragment>
              )
          }
        </main>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CabinetPage);
