// @ts-ignore
import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';

import { eventChannel, EventChannel } from 'redux-saga';
import {
  call,
  take,
  put,
  select,
  takeLatest,
  all,
  delay,
  cancelled,
} from 'redux-saga/effects';

import * as Sentry from '@sentry/react';

import * as ConfigAPI from '../config/api';
import { INIT_START } from '../actions/cabinet/CabinetActionsTypes';
import {
  getAccessToken,
  getRefreshToken,
  setTokens,
} from '../helpers/tokensHelpers';
import {
  setSocketConnectStatus,
  socketEventReserveConfirmation,
  socketEventReserveCreated, socketReconnect,
} from '../actions/webSocketService/WebSocketActions';
// import { customSnackBart } from '../actions/snackbars/SnackBarsActions';
import { getBookingList } from '../actions/booking/BookingActions';

import { IAppStore } from '../types/IAppStore';
import { IInitStarting } from '../actions/cabinet/ICabinetActions';

import {
  getCashBoxConnectStatus,
  getCashBoxPluginsStatus,
} from '../actions/cabinet/CabinetActions';
import { connectRestaurantSuccess } from '../actions/home/HomeActions';

import { addOpenReserve, removeOpenReserve } from '../actions/reservationsPage/ReservationsPageActions';

import { SOCKET_RECONNECT } from '../actions/webSocketService/WebSocketActionsTypes';
import * as Api from '../api';
import { signOut } from '../actions/auth/AuthActions';
import { newNotification } from '../actions/notification/NotificationActions';

import { addRestaurantCall, removeRestaurantCall } from '../actions/restaurantsCalls/RestaurantsCallsActions';
import { IRestaurantCall } from '../types/IRestaurantCallsStore';
import { outerChangeTypeAppReserve } from '../actions/appReserves/AppReservesActions';

import { queryWithRetry } from '../api/utils';

const websocketEmitterType = {
  error: 'ERROR',
  close: 'CLOSE',
  connect: 'CONNECT',
  connectRestaurant: 'CONNECT_RESTAURANT',

  // Резервы
  newReserve: 'NEW_RESERVE', // @todo Проверить приходит ли такой тип?
  confirmReserve: 'CONFIRM_RESERVE',
  update: 'UPDATE',

  newCall: 'NEW_CALL',
  callEnded: 'CALL_ENDED',
};

const websocketError = {
  invalidToken: 'invalid_token',
  unknownError: 'unknown_error',
};

function createSocketChannel() {
  const subscribe = (emitter: any) => {
    const ws = new SockJS(ConfigAPI.websocket, { transports: ['websocket'] });
    const client = Stomp.over(ws, { debug: false });
    const token = getAccessToken();

    ws.onClose = emitter({
      type: websocketEmitterType.close,
    });

    const connectCallback = () => {
      emitter({
        type: websocketEmitterType.connect,
      });

      const reservationCreatedCallback = (message: any) => {
        if (message.body) {
          const json = JSON.parse(message.body);

          emitter({
            type: websocketEmitterType.newReserve,
            payload: json,
          });
        }
      };

      client.subscribe('/user/event/restaurant/reservation/create', reservationCreatedCallback, {});

      const confirmationRequestCallback = (message: any) => {
        if (message.body) {
          const json = JSON.parse(message.body);

          emitter({
            type: websocketEmitterType.confirmReserve,
            payload: json,
          });
        }
      };

      client.subscribe('/user/event/restaurant/reservation/confirmation', confirmationRequestCallback, {});

      const updateBookingListCallback = (message: any) => {
        if (message.body) {
          const json = JSON.parse(message.body);

          emitter({
            type: websocketEmitterType.update,
            payload: json,
          });
        }
      };

      client.subscribe('/user/event/restaurant/reservation/update', updateBookingListCallback, {});

      const connectRestaurantCallback = (message: any) => {
        if (message.body) {
          const json = JSON.parse(message.body);

          emitter({
            type: websocketEmitterType.connectRestaurant,
            payload: json,
          });
        }
      };

      client.subscribe('/user/event/restaurant/registered/cashbox', connectRestaurantCallback);


      // Call
      // @todo Может перенести разбор статусов в сагу, как для резервов или резервы сюда?

      const connectRestaurantCallsCallback = (message: any) => {
        if (message.body) {
          const call: IRestaurantCall = JSON.parse(message.body);
          let emitterType: string | null = null;

          switch (call.state) {
            case 'ACTIVE':
            case 'ALERTING':
              emitterType = websocketEmitterType.newCall;
              break;

            case 'MISSED':
            case 'COMPLETED':
              emitterType = websocketEmitterType.callEnded;
              break;

            default:
              Sentry.captureMessage(`Unknown call state "${call.state}"`);
              console.log(`Unknown call state "${call.state}"`);
              break;
          }

          if (emitterType) {
            emitter({
              type: emitterType,
              payload: call,
            });
          }
        }
      };

      client.subscribe('/user/event/restaurant/call', connectRestaurantCallsCallback);
    };

    const errorCallback = (error: any) => {
      const isInvalidToken = error.headers && error.headers.message.search(websocketError.invalidToken);
      emitter({
        type: websocketEmitterType.error,
        error: isInvalidToken ? websocketError.invalidToken : websocketError.unknownError,
        payload: error,
      });
    };

    client.connect({ Authorization: `Bearer ${token}` }, connectCallback, errorCallback);

    return () => client.disconnect();
  };

  return eventChannel(subscribe);
}

function* watchChannelEvents<T>(channel: EventChannel<T>) {
  while (true) {
    const event = yield take(channel);

    console.info('---socket_event---', event);

    const currentRestaurant = yield select(
      (state: IAppStore) => state.cabinet.currentRestaurant,
    );

    const date = yield select(
      (state: IAppStore) => state.booking.currentStatisticDate,
    );

    switch (event.type) {
      case websocketEmitterType.connect:
        yield put(setSocketConnectStatus(true));
        break;

      case websocketEmitterType.close:
        yield put(setSocketConnectStatus(false));
        channel.close();
        return false;

      case websocketEmitterType.error:
        if (event.error === websocketError.invalidToken) {
          const refreshToken = getRefreshToken();
          try {
            if (refreshToken) {
              const res = yield Api.refreshTokenReq(refreshToken);
              if (res.status === 200) {
                setTokens(res.data.access_token, res.data.refresh_token);
              } else {
                yield put(signOut());
              }
            }
          } catch (e) {
            yield put(signOut());
          }
        }

        yield put(setSocketConnectStatus(false));
        channel.close();
        return false;

      case websocketEmitterType.connectRestaurant:
        yield put(connectRestaurantSuccess());
        yield put(getCashBoxConnectStatus(currentRestaurant.id));
        yield put(getCashBoxPluginsStatus(currentRestaurant.id));
        break;

      case websocketEmitterType.confirmReserve:
        // Когда поступает новый резерв через виджет

        yield put(newNotification('reserve_confirmation'));
        yield put(socketEventReserveConfirmation());

        yield put(addOpenReserve(event.payload));

        break;

      case websocketEmitterType.newReserve:
        // yield put(customSnackBart({
        //   message: 'Создан новый резерв',
        // }));

        yield put(socketEventReserveCreated());
        break;

      case websocketEmitterType.update:
        yield put(getBookingList(currentRestaurant, date));

        if (['CANCELED'].indexOf(event.payload.bookingState) !== -1) {
          yield put(outerChangeTypeAppReserve('CANCELED', event.payload.id));
          yield put(removeOpenReserve(event.payload.id));
        }

        break;

      case websocketEmitterType.newCall:
        yield put(addRestaurantCall(event.payload));
        break;

      case websocketEmitterType.callEnded:
        yield put(removeRestaurantCall(event.payload.id, false));
        break;

      default:
        break;
    }
  }
}

export function* websocketSaga({ type }: IInitStarting) {
  const authorized = yield select((state: IAppStore) => state.auth.authorized);

  if (type !== INIT_START || !authorized) {
    try {
      // make a query, if user is not authorized, token renewal mechanic
      // will be triggered.
      yield queryWithRetry(Api.getAccountReq, {}, Infinity, 5000);
    } catch (err) {
      if (yield cancelled()) {
        return false;
      }
      if (err && err.response && String(err.response.status) === '404') {
        return false;
      }
      yield delay(5000);
      yield put(socketReconnect());
      return false;
    }
  }

  const channel = yield call(createSocketChannel);

  try {
    return yield watchChannelEvents(channel);
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}

export default function* saga() {
  yield all([
    takeLatest([
      INIT_START,
      SOCKET_RECONNECT,
    ], websocketSaga),
  ]);
}
